// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LeadConfirmed from "../LeadConfirmed.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function LeadConfirmedZenlayerBareMetal$default(props) {
  return JsxRuntime.jsx(LeadConfirmed.make, {
              leadType: "ZenlayerBareMetal"
            });
}

var $$default = LeadConfirmedZenlayerBareMetal$default;

export {
  $$default as default,
}
/* LeadConfirmed Not a pure module */
